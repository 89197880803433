import React from 'react'
import Breadcrumb from '../../components/breadcrumb/breadcrumb'
import TeacherComponent from '../../components/Teacher/Teacher'
import Teacher1 from '../../components/Teacher1/Teacher1'
import SEO from '../../components/SEO'

 const TeacherPage = ({title,siteName, siteDescription, siteKeywords})=> {
    return (
        <>
        <SEO 
            pageTitle={title}
            siteName={siteName}
            siteDescription={siteDescription}
            siteKeywords={siteKeywords}
            
        />
        <Breadcrumb title={title}/>
        <div className="page__teacher__wrapper"> 
            <Teacher1 />
            <TeacherComponent />
        </div>
        </> 
    )
}
export default TeacherPage