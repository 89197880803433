

import React from 'react'
import { Helmet } from 'react-helmet'

function SEO({ siteName,pageTitle, description = "", keywords="" }) {

  return (
    <Helmet>
        <title>{`${siteName} | ${pageTitle}`}</title>
        <meta 
            name="description"
            content={description}
        />
        <meta 
            name="keywords"
            content={keywords}
        />
    </Helmet>
  )
}

export default SEO
