import React, { useEffect, useState } from 'react'
import Navbar from './Navbar/Navbar';
import Footer from './Footer/Footer'
import SEO from '../components/SEO'
import { getLayoutInfo, getTopmenuList } from '../utils/func';
import Arr from '../utils/arr';

export default function Layout({ info, children }) {

    // const [info,setInfo] = useState({
    //     site_name: "",
    //     company_name: "",
    //     beian: "",
    //     contact_number:"",
    //     keywords: "",
    //     logo: "",
    //     logo_small: "",
    //     favicon: "",
    //     description: "",
    //     company_address:"",
    //     description: "",
    //     admin_phone: "",
    //     email: ""
    // })

    // const [topmenuList,setTopmenuList] = useState([])

    // useEffect(async () => {
    //     getLayoutInfo().then(result => {
    //         setInfo(result[0])
    //     }).catch(error => {
            
    //     });

    //     // getTopmenuList().then(result => {
    //     //     const arr = Arr.sortMenu(result)
    //     //     setTopmenuList(arr)
    //     // }).catch(error => {
    //     //     console.log("topmenu list error",error)
    //     // })

    // }, [])

    // useEffect(() => {

    // }, []);

    return (
        <div>

            <Navbar 
                contact_number={info?.contact_number}
                phone={info?.admin_phone.replace(/\s|[(]|[)]|[（]|[）]|["]|[-]*/g, '')}
                address={info?.company_address}
                logo={info?.logo_small}
                // topmenuList={topmenuList}
            />

             {children}
            
            <Footer
                company_name={info?.company_name}
                beian={info?.beian}
                contact_number={info?.contact_number}
                phone={info?.admin_phone.replace(/\s|[(]|[)]|[（]|[）]|["]|[-]*/g, '')}
                company_address={info?.company_address}
                email={info?.email}
            />
        </div>
    )
}
