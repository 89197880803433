
import axios from 'axios';
import { DOMAIN, FUNC_EXEC_MYSQL_URL } from '../constants';

export const callFunc = (params) => {
    return new Promise((resolve,reject) => {
        axios.post(FUNC_EXEC_MYSQL_URL, params).then((response) => {
            const data = response.data;
            console.log("func call data is",data)
            if( data.code === 200 ) {
                resolve(data.result)
            } else {
                reject(data)
            }
          });
    })
}

export const getLayoutInfo = () => {
    const params = {
        sql: `SELECT fa_web_base_info.*, fa_web_footer_base_info.company_name,fa_web_footer_base_info.beian,fa_setting.config->'$.admin_phone' as admin_phone
                FROM fa_web_base_info 
                LEFT JOIN fa_web_footer_base_info 
                ON fa_web_base_info.shop_id = fa_web_footer_base_info.shop_id
                LEFT JOIN fa_setting 
                ON fa_setting.shop_id = fa_web_base_info.shop_id
                WHERE fa_web_base_info.domain = "${DOMAIN}" and fa_setting.sign = "sms"`       
    }
    return callFunc(params)
}

export const getContactNumber = () => {
    const params = {
        sql: `SELECT contact_number
                FROM fa_web_base_info
                WHERE domain = "${DOMAIN}"`       
    }
    return callFunc(params)
}



export const getTopmenuList = () => {
    const params = {
      
        sql: `SELECT m.id AS menu_id, m.title AS menu_title, m.link AS menu_link,  sub.id AS submenu_id, sub.category_id AS submenu_category_id, sub.title AS submenu_title, sub.link AS submenu_link
                FROM  fa_web_topmenu_category AS m
                LEFT JOIN  fa_web_base_info AS b ON b.shop_id = m.shop_id
                LEFT JOIN fa_web_topmenu AS sub ON sub.category_id = m.id 
                WHERE b.domain = "${DOMAIN}"
                ORDER BY m.id ASC`      
    }


    return callFunc(params)
}

