import React,{useState,useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ModalVideo from 'react-modal-video'
import whyregister from '../../assets/images/whyregister.jpg'
import school from '../../assets/images/school.png'
import teacher from '../../assets/images/teacher.png'
import book from '../../assets/images/book.png'
 const WhyRegister= ()=> {

    const [isOpen, setOpen] = useState(false)
    
    useEffect(() => {
        console.log('ll')
      },[]);
    return (
        <div className="why__register__wrapper padding__bottom">
             <div className="container-fluid p-0">
                <div className="row no-gutters">
                    <div className="col-lg-6">
                        <div className="why__img">
                           <img src={whyregister} alt=""/>
                           <ModalVideo 
                                channel='youtube' 
                                youtube={{
                                    autoplay: 0
                                    }}
                                isOpen={isOpen} 
                                videoId='f02mOEt11OQ' 
                                onClose={() => setOpen(false)}
                            />
                            <button className="btn video__btn" onClick={()=> setOpen(true)}>
                                <FontAwesomeIcon icon={['fas', 'play']}/>
                            </button>
                        </div>
                    </div>
                    <div className="col-lg-6 why__right__back">
                        <div className="why__right">
                            <div className="section__title__container">
                                <h5 className="section__subtitle1">为什么加入我们?</h5>
                                <h2 className="section__title">我们是您孩子的最佳选择</h2>
                                <p className="section__contnet">
                                    正如我们发自内心的一句话，我们喜欢将
                                    生命。良好的教育是灵魂和思想扎实发展的必要条件
                                    适合孩子们。我们和孩子们一起去玩耍、学习和成长。
                                </p>
                            </div>
                            <div className="list__options">
                                <div className="list__one">
                                    <div className="list__one__half">
                                        <img src={school} alt="school"/>
                                    </div>
                                    <div className="list__one__content">
                                        <h5>训练有素的教师人员</h5>
                                        <p>通过模仿、学习和模仿动物行为实现人形机器人运动</p>
                                    </div>
                                </div>
                                <div className="list__one">
                                    <div className="list__one__half">
                                        <img src={teacher} alt="teacher"/>
                                    </div>
                                    <div className="list__one__content">
                                        <h5>国际课程模式</h5>
                                        <p>国际课程教学模式</p>
                                    </div>
                                </div>
                                <div className="list__one">
                                    <div className="list__one__half">
                                        <img src={book} alt="book"/>
                                    </div>
                                    <div className="list__one__content">
                                        <h5>很棒的基础设施</h5>
                                        <p>现代化的基础设施</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
             </div>
        </div>
        
    )
}
export default WhyRegister