import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useForm, ValidationError } from '@formspree/react';
 const Contact = ({
    title,
    address,
    contactNumber,
    email,
    formspree
 })=> {

    const [state, handleSubmit] = useForm(formspree);
    if (state.succeeded) {
        return <p>谢谢!</p>;
    }

    return (
        <div className="contact__container padding__top__botton"> 
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-2 mx-auto">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="contact__left">
                                    <div className="section__title__container mb-3">
                                        <h5 className="section__subtitle">{title}</h5>
                                        <h2 className="section__title">保持联系</h2>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <input 
                                                type="text" 
                                                className="form-control mb-4" 
                                                id="text" 
                                                name='text'
                                                placeholder="输入您的名字"/>
                                        </div>
                                        <div className="form-group">
                                            <input 
                                                type="text" 
                                                className="form-control mb-4" 
                                                id="phone" 
                                                name='phone'
                                                placeholder="输入您的电话"/>
                                        </div>
                                        <div className="form-group">
                                           <textarea 
                                                className="form-control mb-4" 
                                                name="content" 
                                                id="content" 
                                                cols="30" 
                                                rows="5"
                                                placeholder='输入留言信息'
                                                >

                                            </textarea>
                                        </div>
                                        <button 
                                            type="submit"  
                                            disabled={state.submitting}
                                            className="btn page__contact__btn">发送信息</button>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="contact__right">
                                    <div className="section__title__container mb-3">
                                        <h2 className="section__title">地址</h2>
                                        <p className="section__contnet">
                                        
                                        </p>
                                    </div>
                                    <div className="address__contact">
                                        <div className="single__address">
                                            <FontAwesomeIcon icon={['fas', 'home']}/>
                                            <div className="address__content ml-3">
                                                <p>地址</p>
                                                <p>{address}</p>
                                            </div>
                                        </div>
                                        <div className="single__address">
                                            <FontAwesomeIcon icon={['fas', 'envelope']}/>
                                            <div className="address__content ml-3">
                                                <p>邮箱:</p>
                                                <p>{email}</p>
                                            </div>
                                        </div>
                                        <div className="single__address">
                                            <FontAwesomeIcon icon={['fas', 'phone-volume']}/>
                                            <div className="address__content ml-3">
                                                <p>联系电话:</p>
                                                <p>{contactNumber}</p>
                                            </div>
                                        </div>
                                        <div className="single__address">
                                            <FontAwesomeIcon icon={['fas', 'clock']}/>
                                            <div className="address__content ml-3">
                                                <p>工作时间:</p>
                                                <p>星期一到星期六: 上午8点 - 下午17.30</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    )
}
export default Contact