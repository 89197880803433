import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BrowserRouter as Router,Link } from "react-router-dom";
import blog from '../../assets/images/blog.jpg'
import blog1 from '../../assets/images/blog1.jpg'
import blog2 from '../../assets/images/blog2.jpg'
import blog3 from '../../assets/images/blog3.jpg'
import { getRandomNumber } from '../../utils';
import dayjs from 'dayjs';
 const Blog= ()=> {

    const blogs=[
        {
            src:blog,
            title:'帮助您的孩子',
            para:'帮助您的孩子帮助您的孩子帮助您的孩子帮助您的孩子帮助您的孩子'
        },
        {
            src:blog1,
            title:'教育儿童',
            para:'教育儿童教育儿童教育儿童教育儿童教育儿童教育儿童教育儿童教育儿童'
        },
        {
            src:blog2,
            title:'园所活动',
            para:' 园所活动园所活动园所活动园所活动园所活动园所活动园所活动园所活动园所活动园所活动'
        },
        {
            src:blog3,
            title:'国际学生',
            para:'园所活动'
        }
    ]
    return (
        <div className="blog__wrapper">
             <div className="container">
                <div className="row">
                     <div className="col-lg-8 offset-lg-4 mx-auto ">
                        <div className="section__title__container text-center">
                            <h5 className="section__subtitle">我们一起创造</h5>
                            <h2 className="section__title">每日更新的有趣文章</h2>
                        </div>
                     </div>
                 </div>
                 <div className="row">
                     {blogs.map((blog,i)=>
                        <div className="col-lg-4 col-xl-3 col-md-6" key={i}>
                        <div className="blog__single">
                            <div className="blog__img">
                                <img src={blog.src} className="img-fluid" alt={blog.title}/>
                            </div>
                            <div className="blog__inner__content">
                                <h5 className="blog__title">
                                    <Router>
                                        <Link to="/details">{blog.title}</Link>
                                    </Router>
                                </h5>
                                <p className="blog__para">
                                {blog.para}
                                </p>
                                <ul className="blog__comment">
                                    <li> {getRandomNumber(10,30)} 评论</li>
                                    <li> |  </li>
                                    <li>{dayjs().format('YYYY-MM-DD')} </li>    
                                </ul>
                            </div>
                        </div>
                    </div>
                    )}
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="more__teacher text-center">
                            <Router>
                                <Link to="/teachers" className="teacher__quali">拥有20+名合格教师?​</Link>
                            </Router>
                            <Router>
                                <Link to="/teachers" className="teacher__btn">在这里查看全部 <FontAwesomeIcon icon={['fas', 'caret-right']} /></Link>
                            </Router>
                            {/* <Router>
                                <Link to="/teachers" className="teacher__car">职业机会</Link>
                            </Router> */}
                        </div>
                    </div>
                </div>
             </div>
        </div>  
    )
}
export default Blog