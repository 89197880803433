import React from 'react'
import teacherkid from '../../assets/images/teacherkid.png'
import teacherkid1 from '../../assets/images/teacherkid1.png'
 const Teacher1= ()=> {
    const teachers=[
        {
            src:teacherkid,
            title:'学前班'
        },
        {
           src:teacherkid1,
           title:'最佳老师'
       },
       {
           src:teacherkid,
           title:'优秀的课程'
       },
       {
           src:teacherkid1,
           title:'有趣的游戏'
       }
    ]
    return (
        <div className="teacher1__wrapper padding__top__botton"> 
        <div className="container">
            <div className="row">
                <div className="col-lg-8 offset-lg-4 mx-auto ">
                    <div className="section__title__container text-center">
                        <h5 className="section__subtitle">教育为了每个孩子</h5>
                        <h2 className="section__title">我们对孩子教育的期许</h2>
                        <p className="section__contnet section__margin__bottom">
                         如我们的心声，我们爱把最有价值的东西奉献给孩子们
                            生活。良好的教育是灵魂和思想的坚实发展的必要条件
                            对于孩子们。我们和孩子们一起玩耍、学习和成长。
                        </p>
                    </div>
                 </div>
            </div>
            <div className="row last__child_remove">
                {teachers.map((teacher,i)=>
                    <div className="col-lg-4 col-xl-3 mar__bottom_30" key={i}>
                        <div className="page__teacher__img">
                            <img src={teacher.src}alt={teacher.title}/>
                            <h4>{teacher.title}</h4>
                        </div>
                    </div>
                )}
            </div>
        </div>
    </div>
    )
}
export default Teacher1