

export const getLinkPath = (link) => {
    console.log('getLinkPaht link is', link)
    const { action, param } = typeof link == "string" ? JSON.parse(link) : link;
    console.log('here action is', action)
    let path = '/'
    switch(action) {
        case 'portal': //首页
            path = '/';
            break;
        case 'page': //自定义专题页面
            path = `/page/${param.id}`
            break;    
        case 'goods_category':
            path = `/goods`
            break;    
        case 'goods': // 商品详情
            path = `/goods`
            break; 
        case 'good': // 商品详情
            path = `/good/${param.id}`
            break;      
        case 'url': //自定义链接
            path = param;
            break;      
        case 'services': // 所有服务
            path = `/services`;
          break;    
        case 'service': // 服务详情
           path = `/service/${param.id}`
          break;   
        case 'blogs': // 所有博客
            path = `/blogs`;
          break;    
        case 'blog': // 博客详情
           path = `/blog/${param.id}`
          break;  
        case 'aboutus': // 关于我们
            path = `/aboutus`
            break;       
        case 'contactus': // 联系我们
            path = `/contact`
            break;       
        case 'ourteam': // 我们的团队
           path = `/ourteam`
           break;   
        case 'pricing': // 价格
           path = `/priceing`
           break; 
    }
    
    return path ;
}

export function getRandomNumber(min, max) {
    return Math.round(Math.random() * (max - min) + min);
}