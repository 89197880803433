import React,{useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BrowserRouter as Router,Link } from "react-router-dom";
import ModalVideo from 'react-modal-video'
import Slider1 from "react-slick";
import learnabout from '../../assets/images/learnabout.jpg'
 const LearnAbout= ()=> {

    const [isOpen, setOpen] = useState(false)
    const settings = {
        arrows:false,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        
      };
      const videos = [
            {
                src:learnabout,
                vid:'f02mOEt11OQ',
                url: "https://v.qq.com/x/page/j3519k4y9by.html"
            },
            {
                src:learnabout,
                vid:'',
                url: "https://v.qq.com/x/page/j3519k4y9by.html"
            },
            {
                src:learnabout,
                vid:'KFVdHDMcepw',
                url: "https://v.qq.com/x/page/j3519k4y9by.html"
            },
            {
                src:learnabout,
                vid:'',
                url: "https://v.qq.com/x/page/j3519k4y9by.html"
            }
      ]

    return (
        <div className="learn__about__wrapper padding__top">
             <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="learn__about__img">
                            <Slider1 {...settings}>
                                {videos.map((video,i)=>
                                    <div className="learn__about__img" key={i}>
                                        <img src={video.src} alt="learnabout"/>
                                         {
                                            video.url===''? 0: <>
                                            
                                            <ModalVideo 
                                                channel='custom' 
                                                // youtube={{
                                                //     autoplay: 0
                                                //   }}
                                                isOpen={isOpen} 
                                                // videoId={video.vid} 
                                                url={video.url}
                                                onClose={() => setOpen(false)}
                                            />
                                            <button className="btn video__btn" onClick={()=> setOpen(true)}>
                                                <FontAwesomeIcon icon={['fas', 'play']}/>
                                            </button>
                                            
                                            </>
                                        }
                                    </div>
                                    )
                                }
                            </Slider1>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="learn__right">
                            <div className="up__art">
                                <img src="/images/up.svg" alt=""/>
                            </div>
                            <div className="section__title__container">
                                <h5 className="section__subtitle">人人享有教育</h5>
                                <h2 className="section__title">了解格林的工作文化</h2>
                                <p className="section__contnet">
                                    正如我们发自内心的一句话，我们喜欢将
                                    生命。良好的教育是灵魂和思想扎实发展的必要条件
                                    适合孩子们。我们和孩子们一起去玩耍、学习和成长。
                                </p>
                            </div>
                            <ul className="list__content">
                                <li><FontAwesomeIcon icon="check-circle"/>快乐教育</li>
                                <li><FontAwesomeIcon icon="check-circle"/>营养食品</li>
                                <li> <FontAwesomeIcon icon="check-circle"/>户外游戏</li>
                                <li> <FontAwesomeIcon icon="check-circle"/>最佳护理</li>
                            </ul>
                            <p className="learn__contnet2">
                            正如我们发自内心的一句话，我们喜欢将
                            生命。我们和孩子们一起去玩耍、学习和成长
                            </p>
                            <Router>
                                <h5 className="have__q3">有问题? 
                                <Link to="/" className="start__free">请联系我们</Link></h5>
                            </Router>  
                        </div>
                    </div>
                </div>
             </div>
        </div>
        
    )
}
export default LearnAbout