import React from 'react'
import Bradcrumb from '../../components/breadcrumb/breadcrumb'
import Contact from '../../components/Contact/Contact'
import Map from '../../components/Map/Map'
import SEO from '../../components/SEO'
const ContactPage = ({title,siteName, siteDescription, siteKeywords,address,contactNumber,email,formspree })=> {
    return (
        <>
         <SEO 
            pageTitle={title}
            siteName={siteName}
            siteDescription={siteDescription}
            siteKeywords={siteKeywords}
            
        />
        <Bradcrumb title={title}/>
        <div className="contact__wrapper"> 
            {
                formspree && (
                    <Contact 
                        title={title}
                        address={address}
                        contactNumber={contactNumber}
                        email={email}
                        formspree={formspree}
                />
                )
            }
           
           {/* <Map /> */}
        </div> 
        </>
    )
}
export default ContactPage