import React,{useState,useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from "react-router-dom";
import logo from '../../assets/images/logo.png'
import { getLinkPath } from '../../utils';
 const Navbar= ({
    contact_number,
    phone,
    address,
    logo,
 })=> {
    const [addClass, updateClass] = useState(false)
    const [stkyClass, setStkyClass] = useState(false)
    const [mega, setMega] = useState(false)
    const handleClick =()=>{
        updateClass(true)
    }
    const handleClickCross =()=>{
        updateClass(false)
        window.scrollTo(0, 0);
    }
    const scrollHandleScroll=()=>{
        if (window.scrollY > 20) {
            setStkyClass(true)
        }
        else{
            setStkyClass(false)
        }
    }
    const handleMega=()=>{
        setMega(!mega)
    }
    useEffect(() => {
        window.addEventListener('scroll', scrollHandleScroll);
      },[]);

    function renderLink(item){
        // 有子菜单
        if(item?.children.length > 0) {
            return <li onClick={handleMega} key={`submenu-${item.id}`}><NavLink to={`${getLinkPath(item.link)}@${item?.id}`}>{item?.title}</NavLink>
                        <ul className={"mega "+ (mega?'mega__visible':'')}>
                            {
                                item.children.map(submenu => {
                                    return <li key={`submenu-${submenu.id}`} onClick={handleClickCross}><NavLink to={getLinkPath(submenu.link)}>{submenu.title}</NavLink></li>
                                })
                            }
                        </ul>
                    </li>
        } else {
            return (
                <li
                    key={`li-${item.id}`}
                    onClick={handleClickCross}
                >
                    {
                        item?.link?.action == "portal" ? (
                            <NavLink exact to={getLinkPath(item.link)}>{item?.title}</NavLink>
                            // <NavLink key={`${item.id}`} to={getLinkPath(item.link)}>{item?.title}</NavLink>
                        ) : (
                            <NavLink key={`${item.id}`} to={getLinkPath(item.link)}>{item?.title}</NavLink>
                        )
                    }
                    
                </li>
            )
        }

        
    }
    
    return (
        <>
        <div className="navbar__top">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-10 col-xl-6">
                        <ul className="nav__top__left">
                            {/* <li><FontAwesomeIcon icon={['fas', 'envelope']} />{phone}</li> */}
                            <li><FontAwesomeIcon icon={['fas', 'phone']} />{contact_number}</li>
                            <li><FontAwesomeIcon icon={['fas', 'home']} />{address}</li>
                        </ul>
                    </div>
                    <div className="col-lg-4 col-md-2 col-xl-6">
                    <ul className="top__social">
                        <li><a href="www.factbook.com"><FontAwesomeIcon icon={['fab', 'facebook-f']}/></a></li>
                        <li><a href="www.factbook.com"><FontAwesomeIcon icon={['fab', 'twitter']}/></a></li>
                        <li><a href="www.factbook.com"><FontAwesomeIcon icon={['fab', 'linkedin-in']}/></a></li>
                        <li><a href="www.factbook.com"><FontAwesomeIcon icon={['fab', 'instagram']}/></a></li>
                    </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className={"navbar__wrapper"+ ( stkyClass? ' mystky' : '')}>
             <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="navbar__inner_container">
                            <div className="logo">
                                <h4>
                                    {/* <NavLink to="/">Logo</NavLink> */}
                                    <NavLink to="/"><img src={logo}alt="logo"/></NavLink>
                                </h4>
                            </div>
                            <div className="manu" >  
                                <ul className={addClass? 'vissible' : 'hidden'}>
                                    {/* {
                                        topmenuList && topmenuList?.length > 0 && topmenuList.map((item,index) => {
                                            return renderLink(item)
                                            
                                        })
                                    } */}

                                    <li onClick={handleClickCross}><NavLink exact to="/">首页</NavLink></li>
                                    {/* <li onClick={handleClickCross}><NavLink to="/events">Events</NavLink></li> */}
                                    <li onClick={handleClickCross}><NavLink to="/teachers">教师风采</NavLink></li>
                                    <li onClick={handleClickCross}><NavLink to="/classes">园所课程</NavLink></li>
                                    <li onClick={handleClickCross}><NavLink to="/aboutus">关于我们</NavLink></li>
                                    <li onClick={handleClickCross}><NavLink to="/contact">联系我们</NavLink></li>
                                    {/* <li onClick={handleMega}><NavLink to="/pages" >Pages</NavLink>
                                        <ul className={"mega "+ (mega?'mega__visible':'')}>
                                            <li onClick={handleClickCross}><NavLink to="/events">Events</NavLink></li>
                                            <li onClick={handleClickCross}><NavLink to="/event/details">Events Details</NavLink></li>
                                            <li onClick={handleClickCross}><NavLink to="/classes">Classes</NavLink></li>
                                            <li onClick={handleClickCross}><NavLink to="/classe/details">Classes Details</NavLink></li>
                                            <li onClick={handleClickCross}><NavLink to="/teachers">Teachers</NavLink></li>
                                            <li onClick={handleClickCross}><NavLink to="/teacher/details">Teachers Details</NavLink></li>
                                            <li onClick={handleClickCross}><NavLink to="/aboutus">About Us</NavLink></li>
                                            <li onClick={handleClickCross}><NavLink to="/contact">Contact</NavLink></li>
                                            <li onClick={handleClickCross}><NavLink to="/error">404</NavLink></li>
                                        </ul>
                                    </li> */}
                                </ul>
                                <div className={addClass? 'vissible__cross' : 'hidden__corss'} onClick={handleClickCross}>
                                <FontAwesomeIcon icon="times" />
                                </div>
                                <div className="contact__btn">
                                    <a href={`tel:${phone}`}><span className="whats__ap"><FontAwesomeIcon icon={['fab', 'whatsapp']} /></span>{phone}</a>
                                </div>
                            </div>
                            <div className="mobile__menu" onClick={handleClick}>
                                <span></span>
                               
                            </div>
                        </div>
                    </div>
                </div>
             </div>
        </div>
        </> 
    )
}
export default Navbar