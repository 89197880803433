import React,{useEffect, useState} from 'react'
import Slider from '../../components/Slider/Slider'
import LearnAbout from '../../components/LearnAbout/LearnAbout'
import Service from '../../components/Service/Service'
import Teacher from '../../components/Teacher/Teacher'
import JoinSession from '../../components/JoinSession/JoinSession'
import Blog from '../../components/Blog/Blog'
import Subscriber from '../../components/Subscriber/Subscriber'
import WhyRegister from '../../components/WhyRegister/WhyRegister'
import { getContactNumber } from '../../utils/func'
import SEO from '../../components/SEO'
 const Home= ({ title ,siteName,siteDescription, siteKeywords})=> {

    const [contactNumber,setContactNumber] = useState("")

    useEffect(() => {
       
        document.title = title

        load()
     },[]);

     function load() {
        getContactNumber().then(result => {
            console.log('getcontactnumber result',result)
            setContactNumber(result[0]?.contact_number)
        }).catch(err => {

        })

     }

    return (
        <div className="home__wrapper"> 
            <SEO 
                siteName={siteName}
                pageTitle={title}
                description={siteDescription}
                keywords={siteKeywords} 
                />
            <Slider/>
            <LearnAbout/>   
            <Service/> 
            <WhyRegister />  
            <Teacher/>   
            <JoinSession
                contact_number={contactNumber}
            />
            <Blog />   
            <Subscriber />  
        </div> 
    )
}
export default Home