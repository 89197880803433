import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BrowserRouter as Router,Link } from "react-router-dom";
import work1 from '../../assets/images/work1.jpg'
import work2 from '../../assets/images/work2.jpg'
import work3 from '../../assets/images/work3.jpg'
import work4 from '../../assets/images/work4.jpg'
import work5 from '../../assets/images/work5.jpg'
import work6 from '../../assets/images/work6.jpg'
 const Service= ()=> {

    const services=[
        {
            src:work1,
            title:'美术课',
            price:''
        },
        {
            src:work2,
            title:'舞蹈课',
            price:''
        },
        {
            src:work3,
            title:'篮球课',
            price:''
        },
        {
            src:work4,
            title:'街舞课',
            price:''
        },
        {
            src:work5,
            title:'书法课',
            price:''
        },
        {
            src:work6,
            title:'探索课',
            price:'$90'
        },
    ]
    return (
        <div className="service__wrapper padding__top__botton">
            <div className="side__img__wal">
                <img src="/images/side0.svg" alt=""/>
            </div>
             <div className="container">
                 <div className="row">
                     <div className="col-lg-8 offset-lg-4 mx-auto ">
                        <div className="section__title__container text-center">
                            <h5 className="section__subtitle">教育服务</h5>
                            <h2 className="section__title">了解格林的工作文化</h2>
                            <p className="section__contnet section__margin__bottom">
                            正如我们发自内心的一句话，我们喜欢将
                                    生命。良好的教育是灵魂和思想扎实发展的必要条件
                                    适合孩子们。我们和孩子们一起去玩耍、学习和成长。
                            </p>
                        </div>
                     </div>
                 </div>
                <div className="row">
                {services.map((service,i)=>
                    <div className="col-lg-4" key={i}>
                        <div className="single__service">
                            <div className="overly__shape"></div>
                            <div className="service__innerimg">
                                <img src={service.src} className="img-fluid" alt={service.title}/>
                            </div>
                            <div className="service__inner__content">
                                {/* <div className="content__price">
                                    <p>{service.price}</p>
                                </div> */}
                                <h4 className="service__title">
                                    <Router>
                                        <Link to="/details">{service.title}</Link>
                                    </Router>
                                </h4>
                                <div className="service__blist__wr">
                                    <ul className="service__blist">
                                        <li><FontAwesomeIcon icon="book"/>13 课程</li>
                                        <li><FontAwesomeIcon icon="user"/>20 学生</li>
                                    </ul>
                                    <ul className="service__blist__one">
                                        <li><FontAwesomeIcon icon="map-marker"/>格林幼儿园</li>
                                    </ul>
                                </div>
                                
                                <div className="service__enroll">
                                    <Router>
                                        <Link to="/enroll">现在加入<FontAwesomeIcon icon="angle-right"/></Link>
                                    </Router>
                                </div>
                            </div>
                        </div>
                    </div>
                    )}
                </div>
                {/* <div className="row">
                    <div className="col-lg-12">
                        <div className="more__service text-center">
                            <Router>
                                <Link to="/moreproject">探索更多项目 <FontAwesomeIcon icon="caret-right"/></Link>
                            </Router>
                        </div>
                    </div>
                </div> */}
             </div>
        </div>  
    )
}
export default Service