import React,{useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Slider1 from "react-slick";
import { BrowserRouter as Router,Link } from "react-router-dom";
import ModalVideo from 'react-modal-video'
import slider from '../../assets/images/slider.svg'
import kid1 from '../../assets/images/kid1.svg'
import kid2 from '../../assets/images/kid2.svg'

 const Slider= ()=> {
    // const [addClass, updateClass] = useState(false)
    const [isOpen, setOpen] = useState(false)
    const [clickVideo, setClickVideo] = useState('KFVdHDMcepw')
    const [videoUrl,setVideoUrl] = useState("")
    const SampleNextArrow=(props)=>{
        const { onClick } = props;
        return (
           <div 
           className="slick__arrow__next"
           onClick={onClick}
           >
                <FontAwesomeIcon icon={['fas', 'arrow-right']}/>
           </div>
        )
    }
    const SamplePrevArrow=(props)=>{
        const { onClick } = props;
        return (
            <div 
            className="slick__arrow__prev"
            onClick={onClick}
            >
                <FontAwesomeIcon icon={['fas', 'arrow-left']}/>
            </div>
        )
    }
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
        
      };

      const SliderContents=[   
          {
              h1a:'我们是幼儿园',
              h1:'专业的',
              p:"没有什么比一些美好的记忆，特别是童年的记忆更高、更强大、更健康、对未来的生活更有益的了。",
              vid:'KFVdHDMcepw',
              url:"https://v.qq.com/x/page/j3519k4y9by.html"
          },
          {
            h1a:'我们是幼儿园',
            h1:'专业的',
            p:"没有什么比一些美好的记忆，特别是童年的记忆更高、更强大、更健康、对未来的生活更有益的了。",
            vid:'KFVdHDMcepw',
            url:"https://v.qq.com/x/page/j3519k4y9by.html"
        },
        {
            h1a:'我们是幼儿园',
            h1:'专业的',
            p:"没有什么比一些美好的记忆，特别是童年的记忆更高、更强大、更健康、对未来的生活更有益的了。",
            vid:'KFVdHDMcepw',
            url:"https://v.qq.com/x/page/j3519k4y9by.html"
        },
        {
            h1a:'我们是幼儿园',
            h1:'专业的',
            p:"没有什么比一些美好的记忆，特别是童年的记忆更高、更强大、更健康、对未来的生活更有益的了。",
            vid:'KFVdHDMcepw',
            url:"https://v.qq.com/x/page/j3519k4y9by.html"
        },
      ]
    const videoHandle =(vid, url)=>{
        setOpen(true)
        setClickVideo(vid)
        setVideoUrl(url)
    }
    return (
        <div className="slider__wrapper">
             <div className="container">
                <div className="row row__reverce">
                    <div className="col-lg-6">
                       <div className="slider__inner">
                       <ModalVideo 
                        channel='custom' 
                        youtube={{
                            autoplay: 0
                            }}
                        isOpen={isOpen} 
                        videoId={clickVideo}
                        url={videoUrl}
                        onClose={() => setOpen(false)}
                                            />
                           <Slider1 {...settings}>
                           {SliderContents.map((SliderContent,i)=>
                                <div className="single__slider" key={i}>
                                <div className="video__modal">
                                    {
                                        SliderContent.vid===''? '':
                                        <button className="btn video__btn" onClick={()=>videoHandle(SliderContent.vid, SliderContent.url)}>
                                            <FontAwesomeIcon icon={['fas', 'play']}/>
                                        </button>
                                    }
                                </div>
                                <h1><span className="above__title">{SliderContent.h1a} </span>
                                {SliderContent.h1}
                                </h1>
                                <p className="slider__para">{SliderContent.p}</p>
                                <div className="slider__btns">
                                    <Router>
                                        <Link to="/contact" className="cmn__btn">现在申请<FontAwesomeIcon icon={['fas', 'arrow-right']}/></Link>
                                        <Link to="/" className="learn__btn cmn__btn">了解更多<FontAwesomeIcon icon={['fas', 'arrow-right']}/></Link>
                                    </Router>
                                </div>
                                </div>

                           )} 
                           </Slider1>
                       </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="slider__image">
                            <img src={slider} alt="" className="img-fluid"/>
                        </div>
                    </div>
                </div>
             </div>
             <div className="slider__side__color">
                <img src={kid2} alt="kid2"/>
            </div>
            <div className="slider__side__color1">
                <img src={kid1} alt="kid1"/>
            </div>
        </div>
        
    )
}
export default Slider