import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BrowserRouter as Router,Link } from "react-router-dom";
 const JoinSession= ({
    contact_number
 })=> {
    return (
        <div className="joinsession__wrapper margin__bottom">
             <div className="container">
                <div className="row">
                     <div className="col-sm-12 offset-sm-0 col-lg-6 text-center offset-lg-5 mx-auto">
                         <h5 className="session__sub">加入我们的新学期</h5>
                         <h2 className="session__title">为您的孩子报名电话 {contact_number}</h2>
                         <Router>
                            <Link to="/" className="session__join">现在就给我们打电话<FontAwesomeIcon icon="caret-right"/></Link>
                        </Router>
                     </div>
                 </div>
             </div>
        </div>  
    )
}
export default JoinSession