import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom";
 const Activities= ()=> {

    const activies=[
        {
            icon:'home',
            title:'博物馆参观',
            content:' 每周提供参观城市博物馆等活动，以拓宽孩子的小世界。'
        },
        {
            icon:'calculator',
            title:'数学俱乐部',
            content:' 每周提供参观城市博物馆等活动，以拓宽孩子的小世界。'
        },
        {
            icon:'air-freshener',
            title:'夏令营',
            content:' 每周提供参观城市博物馆等活动，以拓宽孩子的小世界。'
        },
        {
            icon:'shower',
            title:'干净清晰',
            content:' 每周提供参观城市博物馆等活动，以拓宽孩子的小世界。'
        }
    ]
    return (
        <div className="activities__wrapper padding__top__botton">
             <div className="container">
                 <div className="row">
                     <div className="col-lg-8 offset-lg-4 mx-auto ">
                        <div className="section__title__container text-center">
                            <h5 className="section__subtitle">幼儿教育</h5>
                            <h2 className="section__title">
                            了解我们格林的工作文化</h2>
                            <p className="section__contnet section__margin__bottom">
                            正如我们的心声，我们爱把最有价值的东西奉献给孩子们
                                生活。良好的教育是灵魂和思想的坚实发展的必要条件
                                对于孩子们。我们和孩子们一起玩耍、学习和成长。
                            </p>
                        </div>
                     </div>
                 </div>
                <div className="row ac__icon__back">
                {activies.map((activie,i)=>
                    <div className="col-md-6 col-lg-3" key={i}>
                        <div className="ac__single">
                            <div className="ac__icon">
                                <FontAwesomeIcon icon={['fas', activie.icon]}/>
                            </div>
                            <h5 className="ac__title">{activie.title}</h5>
                            <p className="ac__content">
                                {activie.content}
                            </p>
                            {/* <div className="ac__read__more">
                                <Link to="/enroll">Read More<FontAwesomeIcon icon="angle-right"/></Link>
                            </div> */}
                        </div>
                    </div>
                    )}
                </div>
                
             </div>
        </div>  
    )
}
export default Activities