import React, {useEffect, useState} from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import './assets/style.css';
import './assets/responsive.css';
import Layout from './Layout';
import { BrowserRouter as Router,Switch,Route } from "react-router-dom";
import 'react-modal-video/css/modal-video.min.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Home from './pages/Home/Home';
import AboutUs from './pages/AboutUs/AboutUs';
import Events from './pages/Events/EventsPage';
import Classes from './pages/Classes/Classes';
import Teachers from './pages/TeacherPage/TeacherPage';
import Contact from './pages/ContactPage/ContactPage';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import EventDetails from './pages/EventsDetails/EventsDetails';
import TeacherDetails from './pages/TeacherDetails/TeacherDetails';
import ClassesDetails from './pages/ClassesDetails/ClassesDetails';
import { getLayoutInfo } from './utils/func';

const App= ()=> {

  const [info,setInfo] = useState({
      site_name: "",
      company_name: "",
      beian: "",
      contact_number:"",
      keywords: "",
      logo: "",
      logo_small: "",
      favicon: "",
      description: "",
      company_address:"",
      description: "",
      admin_phone: "",
      email: "",
      formspree:""
  })

  useEffect(async () => {
      getLayoutInfo().then(result => {
          setInfo(result[0])
      }).catch(error => {
          
      });


  }, [])


  return (
    <div className="app">
      <Router>
        <Layout
          info={info}
        >
          <Switch>
            <Route path="/" exact component={() => <Home 
                                                      title="首页" 
                                                      siteName={info?.site_name} 
                                                      siteDescription={info?.description}
                                                      siteKeywords={info?.keywords}
                                                     />}/>
            <Route path="/error" component={() => <ErrorPage title="错误" />}/>
            <Route path="/contact" component={() => <Contact 
                                                      title="联系我们" 
                                                      siteName={info?.site_name} 
                                                      siteDescription={info?.description}
                                                      siteKeywords={info?.keywords}
                                                      address={info?.company_address}
                                                      contactNumber={info?.contact_number}
                                                      email={info?.email}
                                                      formspree={info?.formspree}
                                                      />}/>
            <Route path="/aboutus" component={() => <AboutUs 
                                                      title="关于我们" 
                                                      siteName={info?.site_name} 
                                                      siteDescription={info?.description}
                                                      siteKeywords={info?.keywords}
                                                      />}/>
            <Route path="/teachers" component={() => <Teachers 
                                                        title="教师风采" 
                                                        siteName={info?.site_name} 
                                                        siteDescription={info?.description}
                                                        siteKeywords={info?.keywords}
                                                    />}/>
            <Route path="/teacher/details" component={() => <TeacherDetails title="teachers" title1="details" />}/>
            <Route path="/events" component={() => <Events title="events" />}/>
            <Route path="/classes" component={() => <Classes 
                                                        title="园所课程" 
                                                        siteName={info?.site_name} 
                                                        siteDescription={info?.description}
                                                        siteKeywords={info?.keywords}
                                                        />}/>
            <Route path="/classe/details" component={() => <ClassesDetails title="classes" title1="details" />}/>
            <Route path="/event/details" component={() => <EventDetails title="events" title1="details" />}/>
          </Switch>
        </Layout>
      </Router>
      
    </div>
  );
}

export default App;
